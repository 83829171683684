<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content"  v-if="!viLoader">
      <!-- <div class="dispflex">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
        <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet" v-if="showData">
            <div class="widjetcontent ">
                <div
                      class="form-group input-group  pt-3 px-4"
                      style="border-bottom: 1px solid #e5e5e5"
                    >
                      <span style="color: #6c757d" class="mr-3">
                        Payment Live Mode
                        <span class="required">*</span></span
                      >
                      <label class="switch">
                        <input
                          type="checkbox"
                          v-model="accountDetails.isPayMentMode"
                          id="togBtn"
                        />
                        <div class="slider"></div>
                      </label>
                    </div>
                    <div class=" p-4 mt-2" v-if="showData">
            
            <form data-vv-scope="razorPay" id="parentRegisterid" accept-charset="utf-8">
              <div class="form-row">
                <div class="form-group input-group col-md-12">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      name="accountId"
                      class="form-control web-form-input"
                      id="fnameid"
                      v-validate="'required'"
                      v-model="accountDetails.razorPayAccountId"
                    />

                    <label v-if="errors.first('razorPay.accountId')" class="vi-error" style="top: -1em">Account ID is required</label>
                 
                    <label v-else for="fnameid" >Account ID <span class="required">*</span></label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3" v-if="accountDetails.isPayMentMode">
                  <span class="web-has-float-label">
                    <input
                      v-model="accountDetails.razorPayKeyId"
                      type="text"
                      name="livekey"
                      class="form-control web-form-input"
                      id="fnameid"
                      
                    />
                    <label v-if="errors.first('razorPay.livekey')" class="vi-error" style="top: -1em">Live Mode Key ID is required</label>
                 
                    <label v-else for="fnameid" >Live Mode Key ID <span class="required">*</span></label>
                   
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3" v-if="accountDetails.isPayMentMode">
                  <span class="web-has-float-label">
                    <input
                     v-model="accountDetails.razorPaySecretId"
                      type="text"
                      name="liveScretsssKey"
                      class="form-control web-form-input"
                      id="fnassmeid"
                    />

                    <label v-if="errors.first('razorPay.liveScretsssKey')" class="vi-error" style="top: -1em">Live Mode Key Secret is required</label>
                 
                    <label v-else for="fnameid" >Live Mode Key Secret <span class="required">*</span></label>

                  </span>
                </div>
                 <div class="form-group input-group col-md-6 mt-3" v-if="!accountDetails.isPayMentMode">
                  <span class="web-has-float-label">
                    <input
                     v-model="accountDetails.razorPayTestKeyId"
                      type="text"
                      name="testModeKey"
                      class="form-control web-form-input"
                      id="fnameid"
                      v-validate="'required'"
                      maxlength="50"
                    />

                    <label v-if="errors.first('razorPay.testModeKey')" class="vi-error" style="top: -1em">Test Mode Key ID is required</label>
                 
                    <label v-else for="fnameid" >Test Mode Key ID <span class="required">*</span></label>

                  </span>
                </div>
                <div class="form-group input-group col-md-6" v-if="!accountDetails.isPayMentMode">
                  <span class="web-has-float-label"> mt-3
                    <input
                     v-model="accountDetails.razorPayTestSecretId"
                      type="text"
                      name="testKeyMode"
                      v-validate="'required'"
                      class="form-control web-form-input"
                      id="fnameid"
                      maxlength="50"
                    />

                    <label v-if="errors.first('razorPay.testKeyMode')" class="vi-error" style="top: -1em">Test Mode Key Secret is required</label>
                 
                    <label v-else for="fnameid" >Test Mode Key Secret <span class="required">*</span></label>

                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                          name="Billingmode"
                          class="form-control form-input"
                          id="modeid"
                          v-model="accountDetails.convenienceFee"
                          placeholder="Billing Mode"
                        >
                        <option value='' >  -- Select --  </option>
                        <option v-for="(item,index) in modeList" :key="index" :value="item">
                          {{ item }}
                        </option>
                        
                        </select>
                        
                          <label >Select Convenience Fee Mode <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-6 mt-3">						
                        <span class="has-float-label" v-if="accountDetails.convenienceFee == 'Rs($)'">
                        <input  name="disamount"   v-model="accountDetails.convenienceFeeAmount"  class="form-control form-input">
                         
                        <label for="pervalid">Convenience Amount <span class="required">*</span></label>
                        </span>
                        <span class="has-float-label" v-if="accountDetails.convenienceFee == 'Percentage(%)'">
                        <input  name="penalityAmount"  v-model="accountDetails.convenienceFeePercentage"  class="form-control form-input" id="pervalid"  >
                        
                        <label for="pervalid">Convenience (%) <span class="required">*</span></label>
                        </span>
                     </div>
                <div class="form-group input-group col-md-12" >
                  
                </div>
              </div>
              <div class="form-row">
                <div class="form-group input-group col-md-12">
                 Link to Setup your <a @click="navToRazorPay" target="_blank" style="margin-left: 6px;color: dodgerblue;margin-right: 6px;">  Payment Gateway</a> For any assitance, Please contact Vidhyaan through our Chat Support
                </div>
               
              </div>
            </form>
            
          </div>
            </div>
            <div class="widjetfooter dispflex">
                <label class="web-custradio mt-2"><div slot="label"> Please read the <a @click="navTOTermsAndConditions" target="_blank" style="color: dodgerblue;">Terms and conditions</a> before adding your account </div></label>
                <button
                      type="button"
                      class="btn btnsml"
                      id="svbtn"
                      @click.prevent="updateDetails"
                    >
                        <span>Save</span>
                    </button>
            </div>
          </div>
          <div class="widjet" v-if="!showData" style="height: 235px;">
            <!-- <div class="widjetcontent "> -->
              <div class="form-row" style="padding-top: 65px ">
              <span style="margin-left: 33%;" > If you want access you should Verify vidhyaan login password</span>
            </div>
              <div class="form-row" style="margin-top: 30px;margin-left: 537px;">
                <button
                      type="button"
                      class="btn btnsml"
                      id="svbtn"
                     @click="showAccessPopUp"
                    >
                       <span>Verify</span>
                    </button>
              </div>
            <!-- </div> -->
      </div>

      <!-- <div class="widjet" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <span style="margin-left: 42%;" v-if="!isLoader">Fetching...</span>
          </div>
        </div> -->

        </div>
      </div>
     
    </div>
    <div>
      <b-modal id="deleteFeeTypePopup" no-close-on-backdrop
      no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeAccessPopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Password Confirmation</h2>
        <br />
        <form class="w-100" data-vv-scope="passWordValidate" id="addteacher" accept-charset="utf-8">
                  <div class="form-row m-0">
                    <div class="form-group input-group mt-3 col-md-3" >
                     
                    </div>
                    <div class="form-group input-group mt-3 col-md-4" style="width: 200px;">
                      <span class="web-has-float-label">
                        <input
                          v-model="accessPwd"
                          class="form-control form-input"
                        
                         
                        />
                      
                       
                        <label for="passwordid" 
                          >Password<span class="required">*</span></label
                        >
                      </span>
                    </div>
                    

                    <button
                      type="submit"
                      @click.prevent="checkPwd"
                      class="web-custbutton"
                      style="width: 102px;
    margin-left: 39px;
    height: 41px;
    padding: -8px;margin-top: 13px;"
                      
                    >
                      Check
                    </button>
                    <button
                @click.prevent="closeAccessPopUp"
                type="button"
                class="btn btncl"
                id="clbtn"
                style="width: 123px;
    margin-left: 2px;
    height: 41px;
    margin-top: 13px;"
              >
                   <span>Cancel</span>
              </button>
                  </div>
                </form>
      </div>
      <!-- </div> -->
    </b-modal>
    </div>
   <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>

<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "Payment",
  components: {
    ViSpinner,
  },
  data() {
    return {
     accountDetails: {
       isPayMentMode: false,
       razorPayKeyId: null,
       razorPayTestKeyId: null,
       razorPayTestSecretId: null,
       razorPaySecretId: null,
       razorPayAccountId: null,
       convenienceFee: '',
       convenienceFeeAmount: '',
       convenienceFeePercentage: ''
     },
      modeList: [
        "Rs($)",
        "Percentage(%)"
      ],
      viLoader: false,
      showData: false,
      accessPwd: ''
    };
  },
  created() {
    this.getSchoolDetails('new')
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },
  methods: {
    previousRoute(){
      let data = localStorage.getItem('previousTab');
      let data2 = localStorage.getItem('activeTab');
     

        if(data2 == '/feesManagement/home'){
          localStorage.setItem("activeTab",'/feesManagement/home');
          localStorage.setItem("previousTab",'/feesManagement/home');
        }else{
           localStorage.setItem("activeTab",data);
          localStorage.setItem("previousTab",data);
        }
     this.$router.go(-1)
    },
     async navTOTermsAndConditions() {

      window.open("https://razorpay.com/terms/");
    },
    navToRazorPay() {

      window.open("https://rzp.io/i/vEhYVZGma");
    },
    async navTOPrivacyPolicy() {
      window.open("../../../Privacypolicy.html");
    },
    async checkPwd() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let requestBody = {
          pwd: this.accessPwd,
          accountId: userData.userDetails.account._id,
          userName: userData.userDetails.userName
        };
        const response = await ViService.viXPost(
          `/user/verifyAccessPwd`,
          requestBody,
          userData.token
        );
        if (response.isSuccess) {
          this.showData = true,
          this.closeAccessPopUp()
          //this.getAccountDetails();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getSchoolDetails(type){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
         if(type == 'new') {
         this.viLoader = true;
       }
       // this.viLoader = true;
        const response = await ViService.viXGet(`/account/getSchoolDetail?accountId=${userData.userDetails.account._id}`,userData.token);

        if (response.isSuccess) {
          
          const details = secureUI.secureGet(response.data);

          this.accountDetails.isPayMentMode = details.isPayMentMode
          
          this.accountDetails.razorPayKeyId  = details.razorPayKeyId || ''
          this.accountDetails.razorPayTestKeyId  = details.razorPayTestKeyId || ''
          this.accountDetails.razorPayTestSecretId  = details.razorPayTestSecretId || ''
          this.accountDetails.razorPaySecretId =  details.razorPaySecretId || ''
          this.accountDetails.razorPayAccountId = details.razorPayAccountId || null,
          this.accountDetails.convenienceFee = details.convenienceFee || ''
          this.accountDetails.convenienceFeeAmount = details.convenienceFeeAmount || ''
          this.accountDetails.convenienceFeePercentage = details.convenienceFeePercentage || ''
        

        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;

      }

    },
    showAccessPopUp(){
      this.$bvModal.show("deleteFeeTypePopup");

    },
    closeAccessPopUp(){
      this.$bvModal.hide("deleteFeeTypePopup");
      this.accessPwd = ''
    },
    
    async updateDetails() {
      let isFormValid = false;

      await this.$validator.validateAll("razorPay").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPut(`/account/updateRazorPayDetails/${userData.userDetails.account._id}`,this.accountDetails,userData.token);

          if (response.isSuccess) {
            this.getSchoolDetails('old')
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    redirectCustomRoute() {
      window.open(
        "https://dashboard.razorpay.com/signin?screen=sign_in&next=app%2Fdashboard"
      );
    },
   }
};
</script>
